@import "./variables.scss";

.mat-icon {
    font-size: 15px !important;
    height: 15px !important;
    width: 15px !important;
}

.request-upload {
    @media only screen and (min-width: 0px) {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        u {
            cursor: pointer;
        }

        .add__request {
            cursor: pointer;
            padding: 16px 24px 16px 24px;
            min-width: fit-content;
            display: flex;
            align-items: center;
            gap: 16px;
            border: 0;
            border-radius: 4px;
            background-color: $primary-lighten-1;

            span {
                color: $default-white;
                font-size: $font-size-body-2;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: 1.25px;
                text-align: center;
            }
        }

        .informations-container {
            .documents {
                padding-bottom: 20px;
                border-bottom: 1px solid $grey-lighten-1;
            }

            h3 {
                font-family: Roboto;
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;
                margin: 20px 0;
            }

            h3.first-title {
                margin: 0px 0px 20px 0px;
            }

            .upload-container {
                margin: 10px 0;
                padding: 10px;
                border: 1px solid $grey-lighten-2;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 24px;

                @media only screen and (max-width: 300px) {
                    padding: 0;
                }

                .uploaded-files {
                    display: flex;
                    flex-direction: column;

                    p {
                        padding-top: 10px;
                        color: $primary-lighten-1;
                        font-size: $font-size-body-1;
                    }

                    .success-upload {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        button {
                            border: none;

                            .delete-icon {
                                filter: invert(55%) sepia(85%) saturate(3939%) hue-rotate(350deg) brightness(100%) contrast(91%);
                            }
                        }
                    }

                }

                .upload-input {
                    z-index: 2;
                    position: absolute;
                    opacity: 0;
                    width: 100px;
                    cursor: pointer;
                }

                button {
                    height: 40px;
                    padding: 0 8px;
                    border-radius: 4px;
                    border: 1px solid $primary-lighten-1;
                    color: $primary-lighten-1;
                    background-color: $default-white;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-size: small;

                    span {
                        margin-right: 5px;

                        @media only screen and (max-width: 479px) {
                            margin-right: 0;
                        }
                    }

                    .show_hide_text {
                        display: none;
                    }

                    &:hover {
                        background-color: darken($default-white, 10);
                    }
                }
            }

            .upload-files-btn-container {
                width: 100%;
                display: flex;
                justify-content: center;

                .upload-files {
                    margin: 20px 0;
                    height: 40px;
                    padding: 0 20px;
                    border-radius: 4px;
                    border: 1px solid $green-lighten-1;
                    color: $default-white;
                    background-color: $green-lighten-1;
                    cursor: pointer;
                    text-transform: uppercase;
                }
            }
        }

        .section-spinner {
            z-index: 99999;
            background: rgba(0, 0, 0, 0.6);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            & .mat-mdc-progress-spinner {
                --mdc-circular-progress-active-indicator-color: white;
            }

            & .spinner {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: white;
                align-items: center;
            }
        }
    }

    // 480px
    @media only screen and (min-width: $mediaMinWidth) {
        .informations-container {
            .upload-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 0;

                button {
                    height: 40px;
                    padding: 0 8px;
                    border-radius: 4px;
                    border: 1px solid $primary-lighten-1;
                    color: $primary-lighten-1;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $default-white;
                    font-size: small;

                    span {
                        margin-right: 5px;
                    }

                    .show_hide_text {
                        display: contents !important;
                    }
                }
            }
        }
    }
}